import vb_1 from '../src/vb_1.jpg';
import vb_4 from '../src/vb_4.jpg';
import vb_7 from '../src/vb_7.jpg';
import vb_8 from '../src/vb_8.jpg';
import vb_9 from '../src/vb_9.jpg';
import vb_logo from '../src/logo_uppercase3.svg';
import fb from '../src/facebook_icon.png';
import ig from '../src/instagram_icon.png';
import csfd from '../src/csfd_icon.svg';
import imdb from '../src/imdb_icon.png';
import email from '../src/icon_email.png';

const Home = () => {
  return (
    <div className="home">
      <div class="slideshow-container">

        <div class="slide_vb fade_vb img_vb" style={{ backgroundImage: `url(${vb_4})` }}>
        </div>

        <div class="slide_vb fade_vb img_vb" style={{ backgroundImage: `url(${vb_7})` }}>
        </div>

        <div class="slide_vb fade_vb img_vb" style={{ backgroundImage: `url(${vb_1})` }}>
        </div>

        <div class="slide_vb fade_vb img_vb" style={{ backgroundImage: `url(${vb_8})` }}>
        </div>

        <div class="slide_vb fade_vb img_vb" style={{ backgroundImage: `url(${vb_9})` }}>
        </div>

      </div>

      <img src={vb_logo} name="logo" alt="logo" id="logo" />
      <div class="social">
        <div class="links">
          <a href="https://www.imdb.com/name/nm9662302/" class="imdb" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${imdb})` }}>IMDB</a>
          <a href="https://www.csfd.cz/tvurce/369485-vojtech-babista/" class="csfd" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${csfd})` }}>ČSFD</a>
          <a href="https://www.facebook.com/babistavojtech" class="facebook" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${fb})` }}>Facebook</a>
          <a href="https://www.instagram.com/vojtechbabistaofficial" class="instagram" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${ig})` }}>Instagram</a>
          <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%76%6F%6A%74%65%63%68%2E%62%61%62%69%73%74%61%40%67%6D%61%69%6C%2E%63%6F%6D" class="email" target="_blank" rel="noreferrer" style={{ backgroundImage: `url(${email})` }}>Instagram</a>
        </div>
      </div>
    </div>
  );
}


export default Home;
