import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Home.js';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
        <script type="text/javascript">{`
        var i;
        var slideIndex = 0;
        var slides = document.getElementsByClassName("slide_vb");
        for (i = 0; i < slides.length; i++) {
          slides[i].classList.add("hidden");
        }
        showSlides();
        function showSlides() {
          if (slideIndex >= slides.length) {slideIndex = 0}
          slides[slideIndex].classList.remove("hidden");
          slides[slideIndex].classList.add("visible");

          if (slideIndex > 0){
            slides[slideIndex-1].classList.remove("visible");
            slides[slideIndex-1].classList.add("hidden");
          }else{
            slides[slides.length - 1].classList.remove("visible");
            slides[slides.length - 1].classList.add("hidden");
          }
          slideIndex++;
          setTimeout(showSlides, 5000);
        }
      `}</script>
        </Helmet>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

      </div>
    </Router>
  );
}

export default App;
